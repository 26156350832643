import React, { useState } from "react"
// import ReactPlayer from "react-player"
import Vimeo from "@u-wave/react-vimeo"

import "./videoUrl.css"

export default ({ url }) => {
  if (!url) {
    return null
  }

  var parts = url.split("/")
  var lastSegment = parts.pop() || parts.pop()

  const [isReady, setIsReady] = useState(false)

  const handleOnReady = () => {
    setIsReady(true)
  }

  return (
    <div className={`player-wrapper ${isReady ? "ready" : ""}`}>
      <div className="loading" />
      <Vimeo
        className="vimeo-player"
        video={lastSegment}
        onReady={handleOnReady}
        width={"100%"}
      />
    </div>
  )
}
