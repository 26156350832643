import React from "react"

import Image from "~components/documentation/image/image"
import VideoUrl from "~components/documentation/videoUrl/videoUrl"
import Video from "~components/documentation/video/video"

import Credits from "./credits"

import "./project.css"

export default ({ project }) => {
  return (
    <div className="project">
      <div className="documentation">
        <>
          {project.images
            ? project.images.map(image => {
                switch (image._type) {
                  case "imageAlt":
                    return <Image key={image._key} fluid={image.asset.fluid} />
                  case "videoUrl":
                    return <VideoUrl key={image._key} url={image.videoUrl} />
                  case "video":
                    return <Video key={image._key} src={image.asset.url} />
                  default:
                    return null
                }
              })
            : null}
        </>
        <Credits credits={project.credits} />
      </div>
    </div>
  )
}
