import React from "react"

export default ({ credits }) => {
  if (!credits) return null

  return (
    <div className="additional-credits">
      {credits.map(({ role, collaborators }) => {
        return (
          <p key={role}>
            {role} by{" "}
            {collaborators.map(({ name, website }) => {
              return website ? (
                <a href={website} target="_blank" rel="noreferrer" key={name}>
                  {name}
                </a>
              ) : (
                <span key={name}>{name}</span>
              )
            })}
          </p>
        )
      })}
    </div>
  )
}
