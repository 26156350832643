import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"

// Import Global Component(s)
import SEO from "../../components/seo"

// Import Local Component(s)
import Project from "./components/project"

export default ({ data: { sanityProject: project } }) => {
  console.log("project", project)
  useEffect(() => {
    document.documentElement.style.setProperty("--color-background", "#64657A")
    document.documentElement.style.setProperty("--color-foreground", "#E5D5D8")
  }, [])

  return (
    <Fragment>
      <SEO title={project.client} />
      <Project project={project} />
    </Fragment>
  )
}

export const query = graphql`
  query ProjectQuery($client: String) {
    sanityProject(client: { eq: $client }) {
      title
      client
      _rawDescription
      images {
        ... on SanityImageAlt {
          _key
          _type
          asset {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
        ... on SanityVideo {
          _key
          _type
          description
          asset {
            url
          }
        }
        ... on SanityVideoUrl {
          _key
          _type
          videoUrl
        }
      }
      credits {
        role
        collaborators {
          name
          website
        }
      }
    }
  }
`
