import React from "react"
import Img from "gatsby-image"

export default ({ fluid }) => (
  <Img
    fluid={fluid}
    backgroundColor={"#e5d5d8"}
    placeholderStyle={{ opacity: 0 }}
  />
)
